import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const Homepagebanner = () => {
  return (
    <>
      <div className="banner">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-5">
              <ScrollAnimation animateIn="fadeInLeft">
                <div className="logo-content">
                  {/* <p className="banner-head text-white">WELCOME TO </p> */}
                  <img
                    className="bannerlogo mx-auto"
                    src="./images/services/a_shape_standee.jpg"
                    alt=""
                  />
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-md-7 banner-content">
              <ScrollAnimation animateIn="fadeInRight">
                <h1
                  className="text-center font-size-base banner-text"
                  style={{ color: "white" }}
                >
                  Digital{" "}
                  <span
                    className="pt-0 px-2 py-2  rounded-pill"
                    style={{
                      color: "#f7db05",
                      backgroundColor: "rgba(245, 238, 39, 0.24)",
                    }}
                  >
                    signage
                  </span>{" "}
                  you can depend on
                </h1>
                <p className="text-center">
                  Our digital signage ensures your information is always on
                  display, reliably, securely, and easily.
                </p>
              </ScrollAnimation>
            </div>
            <a
              className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-fill mb-15 mt-10 w-80 prt-btn-color-white mx-auto"
              style={{ maxWidth: "fit-content" }}
              href="/contactus"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
