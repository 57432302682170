import React from 'react'

export const Signagelist2 = () => {
    return (
        <section className="prt-row tab-section bg-base-grey bg-img3 clearfix">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="elementor-widget-container">
                            <div className="prt_listimgbox_wrapper">
                                <ul className="row">
                                  
                                    <li className="prt_listimgbox_wrap border_yes active">
                                        <div className="preyantechnosys-listimgbox-heading">
                                            <h3 className="prt-box-subtitle"><a>Commercial
                                                Signage</a></h3>
                                        </div>
                                        <div className="award_picture">
                                            <img width="370" height="370" src="./images/signagelists/commercial_signage.jpg"
                                                className="img-fluid" alt="Site image" />
                                        </div>
                                    </li>


                                    <li className="prt_listimgbox_wrap border_yes">
                                        <div className="preyantechnosys-listimgbox-heading">
                                            <h3 className="prt-box-subtitle"><a>Dimensinal
                                                Signage</a></h3>
                                        </div>
                                        <div className="award_picture">
                                            <img width="370" height="370" src="./images/signagelists/dimensinal_signage.jpg"
                                                className="img-fluid" alt="Site image" />
                                        </div>
                                    </li>
                                    <li className="prt_listimgbox_wrap border_yes">
                                        <div className="preyantechnosys-listimgbox-heading">
                                            <h3 className="prt-box-subtitle"><a>LED Digital
                                                Signage</a></h3>
                                        </div>
                                        <div className="award_picture">
                                            <img width="370" height="370" src="./images/signagelists/led_digital_signage.jpg"
                                                className="img-fluid" alt="Site image" />
                                        </div>
                                    </li>

                                    <li className="prt_listimgbox_wrap border_yes">
                                        <div className="preyantechnosys-listimgbox-heading">
                                            <h3 className="prt-box-subtitle"><a>Road Signage</a>
                                            </h3>
                                        </div>
                                        <div className="award_picture">
                                            <img width="370" height="370" src="./images/signagelists/road_signage.jpg"
                                                className="img-fluid" alt="Site image" />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
