import React from 'react'
import { Serviceslider } from './Serviceslider';
import Servicesmarquee from './Servicesmarquee';


export const Servicesinage = () => {
    const serviceslider = [
        {
            service: './images/services/a_shape_standee.jpg',
            // url: '/servicedetails',
            title: 'A Shape Standee'
        },
        {
            service: './images/services/active_LED_video_wall.jpg',
            // url: '/servicedetails',
            title: 'Active LED Video Wall'
        },
        {
            service: './images/services/LED_scrolling_board.jpg',
            // url: '/servicedetails',
            title: 'LED Scrolling Board'
        },
        {
            service: './images/services/LED_video_display_screen.jpg',
            // url: '/servicedetails',
            title: 'LED Video Display Screen'
        },
        {
            service: './images/services/outdoor_LED_fix_screen.jpg',
            // url: '/servicedetails',
            title: 'Outdoor LED Fix Screen'
        },
        {
            service: './images/services/transparent_LED_display.jpg',
            // url: '/servicedetails',
            title: 'Transparent LED Display'
        },
    ];
    const servicesmarquee = [
        {
            marqueetext: 'Active LED Video Calls'
        },
        {
            marqueetext: 'LCD Standee'
        },
        {
            marqueetext: 'LCD Module Indore & Outdoor'
        },
        {
            marqueetext: 'Indoor LCD Fix Series'
        },
        {
            marqueetext: 'Outdoor LED Fix Series'
        },
        {
            marqueetext: 'Transparent LCD Series'
        },
        {
            marqueetext: 'Sports LCD Screen'
        },
        {
            marqueetext: 'LCD Scrolling Board'
        }   
    ]
    return (
        <div>
            <Serviceslider data={serviceslider} isautoPlay={true}/>
            <Servicesmarquee data={servicesmarquee} />
        </div>
    )
}
