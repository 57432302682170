import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export const Serviceslider = ({ data, isautoPlay }) => {
    const options = {
        responsive: {
            0: {
                items: 1,
            },
            678: {
                items: 2,
            },
            992: {
                items: 2,
            },
            1024: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
        autoplay: isautoPlay,
        loop: true,
        dots: false,
        nav: false,  // Ensure navigation is hidden
        margin: 20,
        slideSpeed: 3000,
    };

    return (
        <div>
            <OwlCarousel className="owl-theme" {...options}>
                {data.map((item, index) => (
                    <div className="item" key={index}>
                        <div className="featured-imagebox featured-imagebox-service style2">
                            <div className="featured-thumbnail">
                                <img width="390" height="440" className="img-fluid" src={item.service} alt="services-img" />
                            </div>
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h4><a style={{ color: 'white', marginBottom: '0px!important' }} href={item.url}>{item.title}</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
        </div>
    );
};
