import React from "react";
import { Titlesection } from "./Titlesection";

const awarddetails = [
  {
    position: "mt-0 res-991-mt-0",
    awardimg: "./images/awards/gold_best_use_of_experimental_award.jpg",
    awarddesc: "GOLD BEST USE OF EXPERIENTIAL",
    // awardyear: "2022-2023",
  },
  {
    position: "mt-70 res-991-mt-15",
    awardimg: "./images/awards/silver_best_ev_content_campaign_award.jpg",
    awarddesc: "SILVER BEST EV CONTENT CAMPAIGN",
    // awardyear: "2023-2024",
  },
  {
    position: "mt-0 res-991-mt-0",
    awardimg: "./images/awards/bronze_insight_and_innovation_creative_idea_award.jpg",
    awarddesc: "BRONZE INSIGHT & INNOVATION CREATIVE IDEA",
    // awardyear: "2021-2022",
  }  
];
const award = {
  // sectiontitle: 'Our awards',
  title: 'Our achievements',
}

export const Award = () => {
  return (
    <>
      <section className="prt-row services-section_1 bg-base-grey clearfix">
        <div className="container">
          <Titlesection {...award} />
          <div className="row">
            {awarddetails.map((award, index) => (
              // <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6" key={index}>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12" key={index}>

                <div className={award.position}>
                  <div className="featured-imagebox-service style1">
                    <div className="featured-thumbnail text-center">
                      <img
                        width="350"
                        height="400"
                        className="img-fluid"
                        src={award.awardimg}
                        alt="services-img"
                      />
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5 className="text-center">{award.awarddesc}</h5>
                      </div>
                      <div className="featured-desc">
                        <p>{award.awardyear}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
export default Award;
