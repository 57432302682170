import React, { useEffect, useState } from "react";
import { Pagetitle } from "../Components/Pagetitle";
// import { Marqueecontent } from "../Components/Marqueecontent";
import { Socialmediasection } from "../Components/Socialmediasection";
import axios from 'axios';
import config from '../constant/config.json';
import {states, districtOptions} from '../statesdistrictdata';

export const Contactus = () => {
  const error = {
    color: "red",
  };

  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    state: "",
    district: "",
    locality: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Add loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [formMessage, setFormMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
    // console.log("Form :", formData);
    setLoading(true); // Set loading to true
    try {
      console.log("Form submitted:", formData);
      await axios.post(`${config.API_URL}/api/form/submit`, formData);
      setFormData({
        name: "",
        phoneNumber: "",
        email: "",
        state: "",
        district: "",
        locality: "",
        message: "",
      });
      setErrors({});
      console.log("Form submitted:", formData);
      setFormMessage("Form submitted successfully!");
      setLoading(false); // Set loading to false after successful submission

      // alert("Form submitted successfully!");

    } catch (error) {
      console.error("Error submitting data:", error);
      setFormMessage("Failed to submit form.");
      setLoading(false); // Set loading to false if there's an error
      // alert("failed");
      // toast.error("Error submitting form");

    }
  }else {
    setErrors(validationErrors);
  }
    }  ;

const validateForm = (formData) => {
  let errors = {};

  // Name validation
  if (typeof formData.name === 'string' && !formData.name.trim()) {
    errors.name = "Name is required";
  }

  // Phone validation
  if (typeof formData.phoneNumber === 'string' && !formData.phoneNumber.trim()) {
    errors.phoneNumber = "Phone number is required";
  } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
    errors.phoneNumber = "Invalid phone number";
  }

  // Email validation
  if (typeof formData.email === 'string' && !formData.email.trim()) {
    errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    errors.email = "Invalid email address";
  }

  // State validation
  if (typeof formData.state === 'string' && !formData.state.trim()) {
    errors.state = "State is required";
  }

  // City validation
  if (typeof formData.district === 'string' && !formData.district.trim()) {
    errors.district = "District is required";
  }

  // Locality validation
  if (typeof formData.locality === 'string' && !formData.locality.trim()) {
    errors.locality = "Locality is required";
  }

  // Message validation
  // if (typeof formData.message === 'string' && !formData.message.trim()) {
  //   errors.message = "Message is required";
  // }
  
  return errors;
};

useEffect(() => {
  document.title = "Digital Signage | Contact Us";
}, []);

return (
  <>
    <Pagetitle {...{ name: "Contact Us" }} />

    <div className="site-main">
      <section className="prt-row contect-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="map-contect-form position-relative">
                <div className="section-title">
                  <div className="title-header">
                    <h2 className="title">
                      Have any project in mind? Let’s work together
                    </h2>
                  </div>
                </div>
                <form
                  className="query_form wrap-form clearfix res-991-mt-0 position-relative"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                  {formMessage && <p className="form-message">{formMessage}</p>}

                    <div className="col-md-6">
                      <label>
                        <span className="text-input">
                          <input
                            name="name"
                            type="text"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleChange}
                            style={{ color: "black" }}
                          />
                          {errors.name && (
                            <span className="error" style={error}>
                              {errors.name}
                            </span>
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label>
                        <span className="text-input">
                          <input
                            name="phoneNumber"
                            type="text"
                            placeholder="Your Phone Number"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            style={{ color: "black" }}
                          />
                          {errors.phoneNumber && (
                            <span className="error" style={error}>
                              {errors.phoneNumber}
                            </span>
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label>
                        <span className="text-input">
                          <input
                            name="email"
                            type="text"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleChange}
                            style={{ color: "black" }}
                          />
                          {errors.email && (
                            <span className="error" style={error}>
                              {errors.email}
                            </span>
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label>
                        <span className="text-input">
                          <select
                            className="form-select"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            style={{ color: "black" }}
                          >
                            <option value="">Select State</option>
                            {states.map((state, index) => (
                              <option key={index} value={state}>
                                {state}
                              </option>
                            ))}
                          </select>
                          {/* <i className="fas fa-chevron-down" style={{ position: 'absolute', left: 'revert', right: "10px", top: '50%', transform: 'translateY(-50%)' }}></i> */}
                          {errors.state && (
                            <span className="error" style={error}>
                              {errors.state}
                            </span>
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label>
                        <span className="text-input">
                          <select
                            className="form-select"
                            name="district"
                            value={formData.district}
                            onChange={handleChange}
                            style={{ color: "black" }}
                          >
                            <option value="">Select District</option>
                            {formData.state &&
                              districtOptions[formData.state].map(
                                (district, index) => (
                                  <option key={index} value={district}>
                                    {district}
                                  </option>
                                )
                              )}
                          </select>
                          {/* <i className="fas fa-chevron-down" style={{ position: 'absolute', left: 'revert', right: "10px", top: '50%', transform: 'translateY(-50%)' }}></i> */}
                          {errors.district && (
                            <span className="error" style={error}>
                              {errors.district}
                            </span>
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label>
                        <span className="text-input">
                          <input
                            name="locality"
                            type="text"
                            placeholder="Locality"
                            value={formData.locality}
                            onChange={handleChange}
                          />
                          {errors.locality && (
                            <span className="error" style={error}>
                              {errors.locality}
                            </span>
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="col-md-12">
                      <label>
                        <span className="text-input">
                          <textarea
                            name="message"
                            cols="20"
                            rows="3"
                            placeholder="Message..."
                            value={formData.message}
                            onChange={handleChange}
                            style={{ color: "black" }}
                          ></textarea>
                          {errors.message && (
                            <span className="error" style={error}>
                              {errors.message}
                            </span>
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="col-md-12" >
                      <button
                        className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-fill prt-btn-color-dark mt-25"
                        type="Submit"
                        onChange={handleSubmit}
                      >
                        Submit
                      </button>
                      {/* {loading &&  <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                      </div>} */}
                       {/* {loading &&<span class="spinner-border">
                      </span>} */}
                        
                      {/* <span class="spinner-border"></span> */}
                      {/* <img style={{width: '50px', paddingTop: '20px', borderRadius: '50%'}} src="images/logos/loading.gif" alt="" /> */}
                      {loading && <span><img style={{width: '50px'}} src="images/logos/loading.gif" alt="" /></span>}
                    </div>
                  </div>
                  {/* <ToastContainer position="top-center" bodyClassName="custom-toast-body" /> */}
                </form>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div id="google_map" className="google_map">
                <div className="map_container">
                  <div>
                    {/* <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.844574161123!2d72.85824880999694!3d19.114473250690462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c847fe97646d%3A0x92fb62061db6378c!2sOTTOEDGE%20SERVICES%20LLP%20(Corporate%20Office)!5e0!3m2!1sen!2sin!4v1708348467873!5m2!1sen!2sin"
                      height="565"
                    ></iframe> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241075.16703500753!2d72.7133421327087!3d19.247568676624603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a5002f079bab%3A0xb56ef2ba6128cb14!2sOttoedge%20Factory%20Fibre%20Laser!5e0!3m2!1sen!2sin!4v1714992008406!5m2!1sen!2sin" 
                     height="565" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="prt-row padding_zero-section bg-base-dark overflow-hidden clearfix">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 p-0">
              <div className="marquee-block overflow-hidden">
                <div className="marquee">
                  <Marqueecontent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="social-section clearfix">
        <div className="container">
          {/* <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="section-title title-style-center_text">
                <div className="title-header">
                  <h2 className="title">Follow me on social media</h2>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row justify-content-evenly">
            <Socialmediasection />
          </div>
        </div>
      </section>
    </div>
  </>
);
};
