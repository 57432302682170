import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

export const Vision = () => {
    const imgdetail = {
        img: "./images/vision.jpg",
    };
    return (
        <section className="bg-base-grey" style={{ paddingTop: '100px' }}>
            <div className="container">
                <div className="row">
                    <h2 className="text-center">Our Vision</h2>


                    <div className="col-md-6">
                        <ScrollAnimation animateIn="fadeInLeft">
                            <p>
                                At Digital Signage Company, our vision is to be the market leader in digital communication solutions,
                                transforming environments into vibrant, interactive spaces that connect and engage people like
                                never before. We aspire to push the boundaries of technology and creativity, setting new standards
                                for innovation and excellence in the digital signage industry. Through our commitment to
                                sustainability, inclusivity, and continuous improvement, we envision a world where every screen
                                tells a story, every message matters, and every interaction leaves a lasting impact.
                            </p>
                        </ScrollAnimation>
                    </div>
                    <div className="col-md-6 how-img">
                        <ScrollAnimation animateIn="fadeInRight">
                            <img
                                src={imgdetail.img}
                                className=" rounded mb-30 img-fluid"
                                alt=""
                            />
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </section>
    );
};
